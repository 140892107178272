import React from 'react'

import PropTypes from 'prop-types'

import './author-wrapper.css'

const AuthorWrapper = (props) => {
  return (
    <div className={`author-wrapper-author-wrapper ${props.rootClassName} `}>
      <img
        loading="eager"
        src="/hero-avatar.webp"
        alt="Frame19487575766046"
        className="author-wrapper-hero-avatar-anna"
      />
      <div className="author-wrapper-anna-container">
        <span className="author-wrapper-author-header text-supersmall text">
          АВТОР КУРСА:
        </span>
        <span className="author-wrapper-author-desc text">
          <span className="author-wrapper-text">
            Анна Матари,
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="author-wrapper-text1">психолог-практик</span>
          <span className="">
            , эксперт в техниках работы с подсознанием, основатель Института
            Новой психологии
          </span>
        </span>
      </div>
    </div>
  )
}

AuthorWrapper.defaultProps = {
  rootClassName: '',
}

AuthorWrapper.propTypes = {
  rootClassName: PropTypes.string,
}

export default AuthorWrapper
