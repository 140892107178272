import React from 'react'

import PropTypes from 'prop-types'

import HeroMembersWithTextWhite from './hero-members-with-text-white'
import RegForm from './reg-form'
import './form-block.css'

const FormBlock = (props) => {
  return (
    <div className={`form-block-formblock ${props.rootClassName} `}>
      <div className="form-block-text">
        <span className="form-block-header text text-superheader-2">
          <span>Это шанс!</span>
        </span>
        <span className="form-block-text2 text-header text">
          <span>Используйте его, чтобы помочь себе и близким</span>
        </span>
        <HeroMembersWithTextWhite rootClassName="hero-members-with-text-white-root-class-name1"></HeroMembersWithTextWhite>
      </div>
      <div className="form-block-form">
        <RegForm></RegForm>
      </div>
    </div>
  )
}

FormBlock.defaultProps = {
  rootClassName: '',
}

FormBlock.propTypes = {
  rootClassName: PropTypes.string,
}

export default FormBlock
