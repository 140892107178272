import moment from "moment"
import 'moment/locale/ru'


function ShowNextTimeSlots({returnType}) {
  const timeFormat = 'HH:mm';
  var currentTime = moment.utc().format(timeFormat)
  const timeOffset = moment().utcOffset();
  // console.log('Current UTC time: ' + currentTime + 'UTC offset: ' + timeOffset)
  var firstTimeSlot, secondTimeSlot = ''
  
  const webinarDayTime = moment('09:00', timeFormat); // 12:00 МСК
  const webinarEveningTime = moment('16:00', timeFormat); // 18:00 МСК
  
  if (moment.utc().isBefore(webinarDayTime)) {
    // console.log('Timeslot before webinarDayTime: ' + currentTime )

    // 2 events today
    var firstTimeSlot = moment().locale('ru').format('DD MMMM') + ' в ' + moment(webinarDayTime).add(timeOffset, 'minutes').format(timeFormat)
    var secondTimeSlot = moment().locale('ru').format('DD MMMM') + ' в ' + moment(webinarEveningTime).add(timeOffset, 'minutes').format(timeFormat)
  }
  if (moment.utc().isBetween(webinarDayTime,webinarEveningTime)) {
    // console.log('Timeslot between webinarDayTime and webinarEveningTime: ' + currentTime )
    
    // today and tomorrow
    var firstTimeSlot = moment().locale('ru').format('DD MMMM') + ' в ' + moment(webinarEveningTime).add(timeOffset, 'minutes').format(timeFormat)
    var secondTimeSlot = moment().add(1,'days').locale('ru').format('DD MMMM') + ' в ' + moment(webinarDayTime).add(timeOffset, 'minutes').format(timeFormat)
  }
  if (moment.utc().isAfter(webinarEveningTime)) {
    // console.log('Timeslot after webinarDayTime: ' + currentTime )
    
    // events tomorrow
    var firstTimeSlot = moment().add(1,'days').locale('ru').format('DD MMMM') + ' в ' + moment(webinarDayTime).add(timeOffset, 'minutes').format(timeFormat)
    var secondTimeSlot = moment().add(1,'days').locale('ru').format('DD MMMM') + ' в ' + moment(webinarEveningTime).add(timeOffset, 'minutes').format(timeFormat)
  }

  // console.log(firstTimeSlot)
  // console.log(secondTimeSlot)   

  // This is time slots on hero block 
  if (returnType === 'heroBlock') {
    return (
      <div className="home-next-slots">
        <div className="home-first-slot">
          <div className="home-live-icon">
            <img
              alt="Ellipse9I604"
              src="/live-white-dot.webp"
              className="home-lice-icon-dot"
            />
            <span className="home-live-icon-text text-white text">
              <span>LIVE</span>
            </span>
          </div>
          <span className="home-next-slot-first text-supersmall text">
            <span>{firstTimeSlot}</span>
          </span>
        </div>
        <div className="home-second-slot">
          <span className="home-second-slot-text text-supersmall text">
            <span>{secondTimeSlot}</span>
          </span>
        </div>
      </div>
    )
  }
  
  // This is text on buttons on registration form
  if (returnType === 'sheduleFormButton') {
    return {firstButton: 'Пойду ' + firstTimeSlot, secondButton: 'Пойду ' + secondTimeSlot}
  }

  // This is morning or evening event time in GetCourse
  if (returnType === 'returnGKAPITimesSlots') {
    if (moment.utc().isBefore(webinarDayTime)) {
      // 2 events today
      var firstTimeSlot = 'morningSlot'
      var secondTimeSlot = 'eveningSlot'
    }
    if (moment.utc().isBetween(webinarDayTime,webinarEveningTime)) {
      // today and tomorrow
      var firstTimeSlot = 'eveningSlot'
      var secondTimeSlot = 'morningSlot'
    }
    if (moment.utc().isAfter(webinarEveningTime)) {
      // events tomorrow
      var firstTimeSlot = 'morningSlot'
      var secondTimeSlot = 'eveningSlot'
    }

    return {firstTimeSlot: firstTimeSlot, secondTimeSlot: secondTimeSlot}
  }

}

// import it
// import ShowNextTimeSlots from '../components/ShowNextTimeSlots'

// use it 
// <ShowNextTimeSlots />

export default ShowNextTimeSlots