import moment from "moment"


function TotalMemberCounter () {
  let startDate = moment('2024-01-01').utc()
  let currentTime = moment().utc()
  var duration = moment.duration(currentTime.diff(startDate));
  var minutesCount = Math.floor(duration.asMinutes() / 2)
  
  var totalMembers = 521234 + minutesCount
  var totalMembersStr = totalMembers.toLocaleString('en-US').replaceAll(',' , ' ')

  return totalMembersStr
}


export default TotalMemberCounter