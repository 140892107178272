import React from 'react'

import PropTypes from 'prop-types'

import './hero-members-with-text.css'

import MemberCounter from './MemberCounter'



const HeroMembersWithText = (props) => {
  return (
    <div
      className={`hero-members-with-text-hero-members-with-text ${props.rootClassName} `}
    >
      <div className="hero-members-with-text-hero-members-array">
        <div className="hero-members-with-text-hero-image-1">
          <img
            alt="Ellipse146046"
            src="/member-avatar-1.webp"
            className="hero-members-with-text-hero-image-avatar-1"
          />
        </div>
        <div className="hero-members-with-text-hero-image-2">
          <img
            alt="Ellipse146046"
            src="/member-avatar-2.webp"
            className="hero-members-with-text-hero-image-avatar-2"
          />
        </div>
        <div className="hero-members-with-text-hero-image-3">
          <img
            alt="Ellipse146046"
            src="/member-avatar-3.webp"
            className="hero-members-with-text-hero-image-avatar-3"
          />
        </div>
        <div className="hero-members-with-text-hero-image-4">
          <img
            alt="Ellipse146046"
            src="/member-avatar-4.webp"
            className="hero-members-with-text-hero-image-avatar-4"
          />
        </div>
        <div className="hero-members-with-text-hero-image-counter">
          <span className="hero-members-with-text-hero-member-text text-small text">
            {MemberCounter('count')} 
          </span>
          <img
            alt="Ellipse146046"
            src="/member-avatar-counter.png"
            loading="eager"
            className="hero-members-with-text-hero-image-avatar-41"
          />
        </div>
      </div>
      <span className="hero-members-with-text-hero-registred-members text-small text">
        <span> {MemberCounter('member')} </span>
      </span>
    </div>
  )
}

HeroMembersWithText.defaultProps = {
  rootClassName: '',
}

HeroMembersWithText.propTypes = {
  rootClassName: PropTypes.string,
}

export default HeroMembersWithText
