import moment from "moment"


function MemberCounter (typeReturn) {
  let startOfDay = moment().utc().startOf('day')
  let currentTime = moment().utc()
  var duration = moment.duration(currentTime.diff(startOfDay));
  var membersCount = Math.floor(duration.asMinutes() / 2)
  
  if (membersCount < 74) { membersCount = 74}
  if (membersCount > 967) { membersCount = 967}

  function declOfNum(number, titles) {  
    var cases = [2, 0, 1, 1, 1, 2];  
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
  }

  var membersName = declOfNum(membersCount, ['участник', 'участника', 'участников']);
  
  if (typeReturn === 'count') {
    // console.log(membersCount)
    return membersCount
  }

  if (typeReturn === 'member') {
    // console.log(membersName)
    return membersName
  }


}


export default MemberCounter