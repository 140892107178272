import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ShowNextTimeSlots from '../components/ShowNextTimeSlots'

import './reg-form.css'

const redirectURL = 'https://edu.annamatari.ru/tlgrm'


const urlRegistration = '/api/registration'
// const urlRegistration = 'http://localhost:8080/api/registration'

function RegForm() {
  const { register, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm();

  async function registerNewUser (userData) {
    // console.log('string req')
    // console.log(JSON.stringify(userData)


    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(userData)
    
    }
    console.log(options)
    
    const response = await fetch(urlRegistration, options)
    const responseJSON = await response.json()
    
    if (responseJSON.result.success) { 
      console.log('User registred on GetCourse. Redirect to Telegram')

      window.open(redirectURL,"_self")
    }
    else { 
      console.log('Cannot register user on GetCourse. Check response')
      console.log(responseJSON)
      
    }

  }

  const onFirstSubmitBtn = formData => {
    console.log('First Button');  
    // get utm from URL
    const queryParameters = new URLSearchParams(window.location.search)
    var userData = {
      email: formData.email,
      phone: formData.phone,
      firstname: formData.name,
      timeslot: ShowNextTimeSlots({returnType: 'returnGKAPITimesSlots'}).firstTimeSlot,
      utm_source: queryParameters.get("utm_source"),
      utm_medium: queryParameters.get("utm_medium"),
      utm_content: queryParameters.get("utm_content"),
      utm_campaign: queryParameters.get("utm_campaign"),
      utm_group: queryParameters.get("utm_group")
    }    
    // console.log(userData)
    registerNewUser(userData)
  }

  const onSecondSubmitBtn = formData => {
    console.log('Second Button');  
    // get utm from URL
    const queryParameters = new URLSearchParams(window.location.search)
    var userData = {
      email: formData.email,
      phone: formData.phone,
      firstname: formData.name,
      timeslot: ShowNextTimeSlots({returnType: 'returnGKAPITimesSlots'}).secondTimeSlot,
      utm_source: queryParameters.get("utm_source"),
      utm_medium: queryParameters.get("utm_medium"),
      utm_content: queryParameters.get("utm_content"),
      utm_campaign: queryParameters.get("utm_campaign"),
      utm_group: queryParameters.get("utm_group")
    }    
    // console.log(userData)
    registerNewUser(userData)
  }

  return (
    <div className="reg-form-container">
      <form 
        className="reg-form-reg-form"
          >
        <span className="reg-form-first text-bold text">Укажите данные</span>
        <input 
          type="text" 
          className="input reg-form-nameinput"
          placeholder="Имя" 
          style={errors.name && { border: "1px solid #E94141", color: "#E94141" }}
          {...register("name", {required: true, maxLength: 100, minLength: 3})} 
        />
        
        <input 
          type="text" 
          className="input"
          placeholder="Электронная почта" 
          style={errors.email && { border: "1px solid #E94141", color: "#E94141" }}
          {...register("email", {required: true, pattern: /^\S+@\S+$/i})} 
        />

        <input 
          type="text" 
          className="input"
          placeholder="Номер телефона" 
          style={errors.phone && { border: "1px solid #E94141", color: "#E94141" }}
          {...register("phone", {maxLength: 15, minLength: 9})} 
        />

        <span className="reg-form-choosedate text-bold text">
          <span>Выберите дату</span>
        </span>

        <button 
          type="submit" 
          className="reg-form-submitfirst button"
          onClick={handleSubmit(onFirstSubmitBtn)}>
          {ShowNextTimeSlots({returnType: 'sheduleFormButton'}).firstButton}
        </button>

        <button
          type="submit"
          className="button button-secondary"
          onClick={handleSubmit(onSecondSubmitBtn)}>
          {ShowNextTimeSlots({returnType: 'sheduleFormButton'}).secondButton}
        </button>


        <a
          href="https://npinst.ru/politika.html"
          target="_blank"
          rel="noreferrer noopener"
          className="reg-form-link text-supersmall text"
        >
          Нажимая кнопку, вы принимаете условия политики конфиденциальности
        </a>
        
      </form>
    </div>
  )

  
}

export default RegForm
