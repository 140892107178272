import React from 'react'

import PropTypes from 'prop-types'
import Popup from 'reactjs-popup';

import HeroMembersWithText from './hero-members-with-text'
import HeroMembersWithTextWhite from './hero-members-with-text-white'
import RegForm from './reg-form'
import './form-block-popup.css'

// style for popup window
const overlayStyle = { background: 'rgba(0,0,0,0.5)', zIndex: '2000' }

const FormBlockPopup = (button) => {

  
 if (button === 'greenButton') {
   return (
     <Popup
       trigger={<button className="home-button-book-header button button-green">
           Забронировать
       </button>}
       modal
       nested
       {...{ overlayStyle }}
     >
     {close => (
       <div className={'form-block-popup-container '}>
         <button type="button" className="form-block-popup-button-close-popup" onClick={() => {
                 console.log('modal closed ');
                 close();
               }}>
 
         </button>
         <div className="form-block-popup-formblock">
           <div className="form-block-popup-text3">
             <span className="form-block-popup-header text text-superheader-2">
               <span className="">Это шанс!</span>
             </span>
             <span className="form-block-popup-text5 text-header text">
               <span className="">
                 Используйте его, чтобы помочь себе и близким
               </span>
             </span>
             <HeroMembersWithText
               rootClassName="hero-members-with-text-root-class-name"
               className=""
             ></HeroMembersWithText>
             <HeroMembersWithTextWhite
               rootClassName="hero-members-with-text-white-root-class-name7"
               className=""
             ></HeroMembersWithTextWhite>
           </div>
           <div className="form-block-popup-form">
             <RegForm className=""></RegForm>
           </div>
         </div>
       </div>
     )}
     </Popup>
   )
 }
  
 if (button === 'heroButton') {
   return (
     <Popup
       trigger={<button className="home-hero-book-time button">
       <span className="home-hero-book-time-text text-small text">
         <span>Выбрать время и забронировать место</span>
       </span>
     </button>}
       modal
       nested
       {...{ overlayStyle }}
     >
     {close => (
       <div className={'form-block-popup-container '}>
         <button type="button" className="form-block-popup-button-close-popup" onClick={() => {
                 console.log('modal closed ');
                 close();
               }}>
 
         </button>
         <div className="form-block-popup-formblock">
           <div className="form-block-popup-text3">
             <span className="form-block-popup-header text text-superheader-2">
               <span className="">Это шанс!</span>
             </span>
             <span className="form-block-popup-text5 text-header text">
               <span className="">
                 Используйте его, чтобы помочь себе и близким
               </span>
             </span>
             <HeroMembersWithText
               rootClassName="hero-members-with-text-root-class-name"
               className=""
             ></HeroMembersWithText>
             <HeroMembersWithTextWhite
               rootClassName="hero-members-with-text-white-root-class-name7"
               className=""
             ></HeroMembersWithTextWhite>
           </div>
           <div className="form-block-popup-form">
             <RegForm className=""></RegForm>
           </div>
         </div>
       </div>
     )}
     </Popup>
   )
 }
  
 if (button === 'bonusButton') {
   return (
     <Popup
       trigger={<button className="home-submitfirst button">
       Выбрать время и забронировать
     </button>}
       modal
       nested
       {...{ overlayStyle }}
     >
     {close => (
       <div className={'form-block-popup-container '}>
         <button type="button" className="form-block-popup-button-close-popup" onClick={() => {
                 console.log('modal closed ');
                 close();
               }}>
 
         </button>
         <div className="form-block-popup-formblock">
           <div className="form-block-popup-text3">
             <span className="form-block-popup-header text text-superheader-2">
               <span className="">Это шанс!</span>
             </span>
             <span className="form-block-popup-text5 text-header text">
               <span className="">
                 Используйте его, чтобы помочь себе и близким
               </span>
             </span>
             <HeroMembersWithText
               rootClassName="hero-members-with-text-root-class-name"
               className=""
             ></HeroMembersWithText>
             <HeroMembersWithTextWhite
               rootClassName="hero-members-with-text-white-root-class-name7"
               className=""
             ></HeroMembersWithTextWhite>
           </div>
           <div className="form-block-popup-form">
             <RegForm className=""></RegForm>
           </div>
         </div>
       </div>
     )}
     </Popup>
   )
 }
  
 if (button === 'finalButton') {
   return (
     <Popup
       trigger={<button className="home-hero-book-time1 button">
       <span className="home-hero-book-time-text1 text-small text">
         <span>Выбрать время и забронировать место</span>
       </span>
     </button>}
       modal
       nested
       {...{ overlayStyle }}
     >
     {close => (
       <div className={'form-block-popup-container '}>
         <button type="button" className="form-block-popup-button-close-popup" onClick={() => {
                 console.log('modal closed ');
                 close();
               }}>
 
         </button>
         <div className="form-block-popup-formblock">
           <div className="form-block-popup-text3">
             <span className="form-block-popup-header text text-superheader-2">
               <span className="">Это шанс!</span>
             </span>
             <span className="form-block-popup-text5 text-header text">
               <span className="">
                 Используйте его, чтобы помочь себе и близким
               </span>
             </span>
             <HeroMembersWithText
               rootClassName="hero-members-with-text-root-class-name"
               className=""
             ></HeroMembersWithText>
             <HeroMembersWithTextWhite
               rootClassName="hero-members-with-text-white-root-class-name7"
               className=""
             ></HeroMembersWithTextWhite>
           </div>
           <div className="form-block-popup-form">
             <RegForm className=""></RegForm>
           </div>
         </div>
       </div>
     )}
     </Popup>
   )
 }
}

FormBlockPopup.defaultProps = {
  rootClassName: '',
}

FormBlockPopup.propTypes = {
  rootClassName: PropTypes.string,
}

export default FormBlockPopup
