import React from 'react'
import './home.css'

import { Helmet } from 'react-helmet'
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';

import AuthorWrapper from '../components/author-wrapper'
import HeroMembersWithText from '../components/hero-members-with-text'
import FormBlock from '../components/form-block'
import FormBlockPopup from '../components/form-block-popup'
import HeroMembersWithTextWhite from '../components/hero-members-with-text-white'
import HeroTypeText from '../components/HeroTypeText'
import ShowNextTimeSlots from '../components/ShowNextTimeSlots'
import TotalMemberCounter from '../components/TotalMemberCounter'


const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Анна Матари</title>
        <meta name="description" content="DEV окружение" />
        <meta property="og:title" content="Анна Матари" />
        <meta property="og:description" content="DEV окружение" />
      </Helmet>
      <div className="home-header">
        <div className="home-header-wrapper">
          <div className="home-header-logo-text">
            <div className="home-header-logo">
              <img
                alt="image"
                src="/header-logo.svg"
                className="home-header-logo-image"
              />
            </div>
            <span className="home-header-main-text text">
              Практический онлайн-курс Анны Матари «Чудо каждый день»
            </span>
          </div>
          
            {FormBlockPopup('greenButton')}

        </div>
      </div>
      <div className="home-hero">
        <AuthorWrapper rootClassName="author-wrapper-root-class-name1"></AuthorWrapper>
        <div className="home-hero-wrapper">

          {ShowNextTimeSlots({returnType: 'heroBlock'})}
          <HeroTypeText />


          <AuthorWrapper rootClassName="author-wrapper-root-class-name"></AuthorWrapper>
          <div className="home-hero-member-button">
            
          {FormBlockPopup('heroButton')}
            <HeroMembersWithText></HeroMembersWithText>

          </div>
        </div>
      </div>
      <div className="home-about">
        <div className="home-about-card about-card-flex">
          <div className="home-container1 about-card-text-inside">
            <span className="home-about-card-put text-small text">
              Самостоятельно прошла многолетний путь
            </span>
            <div className="home-ot-container">
              <span className="home-ot-text text">от</span>
            </div>
            <span className="text">
              «Я живу чужой жизнью, нужно срочно всё менять!»
            </span>
            <div className="home-do-container">
              <span className="home-ot-text1 text-white text">до</span>
            </div>
            <span className="text">«Я живу жизнью своей мечты!»</span>
          </div>
        </div>
        <div className="home-about-card1 about-card-flex">
          <span className="text-bold about-card-text-inside text-white text">
            <span>15 лет в </span>
            <span>психологии</span>
          </span>
        </div>
        <div className="home-about-card2 about-card-flex">
          <span className="text-bold about-card-text-inside text-white text">
            &gt; 251 000 аудитории
          </span>
        </div>
        <div className="home-about-card3 about-card-flex">
          <span className="text-bold about-card-text-inside text-white text">
            120 публикаций в СМИ
          </span>
        </div>
        <div className="home-about-card4 about-card-flex">
          <span className="text-bold about-card-text-inside text-white text">
            Автор романа «Разрыв»
          </span>
        </div>
        <div className="home-about-card5 about-card-flex">
          <span className="about-card-text-inside text">
            На мини-курсе я помогу вам пройти этот путь за 4 дня и существенно
            улучшить свою жизнь.
          </span>
        </div>
        <div className="home-about-card6 about-card-flex"></div>
        <div className="home-about-card7 about-card-flex">
          <div className="home-container2 about-card-text-inside">
            <span className="home-about-card-count text-white text">
              <TotalMemberCounter />
            </span>
            <span className="text-white text">
              человек посетили наши мероприятия
            </span>
          </div>
          <span className="about-card-text-inside text-white text-small text home-about-card-speaker">
            Анна — крупнейший спикер по трансперсональной психологии в России
          </span>
        </div>
      </div>
      <div className="home-problems-header">
        <span className="home-problemheader text-small text">
          На конференциях, мастер-группах и в личной практике
        </span>
        <span className="home-text04 text-header text">Я постоянно слышу:</span>
      </div>
      <div className="home-problem-tags">
        <img
          alt="image"
          src="/problems-tags-desktop.webp"
          className="home-problemstagcloud"
        />
        <img
          alt="image"
          src="/problems-tags-mobile.webp"
          className="home-problemstagmobile"
        />
      </div>
      <div className="home-delo-video">
        <div className="home-header1">
          <div className="home-container3">
            <span className="home-deloheader text-superheader text">
              <span className="home-text05">
                Дело
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text06">не в вас</span>
            </span>
            <img
              alt="Ellipse266329"
              src="/text-back-round-desktop.webp"
              className="home-textforeground"
            />
          </div>
          <span className="home-delotext text">
            <span>
              Дело в убеждениях, которые вы впитали из мира, но с этим можно
              и нужно работать. Поэтому, я приглашаю вас на свой практический
              курс: «Чудо каждый день»
            </span>
          </span>
        </div>
        <video
          src="/delo-video.mp4"
          poster="/video-cover-1920.webp"
          preload="none"
          controls
          className="home-video"
        ></video>
        <div className="home-videotags">
          <div className="home-tag video-tags">
            <span className="home-text08 text-video-tags text-white text">
              4 дня практик
            </span>
          </div>
          <div className="home-tag1 video-tags">
            <span className="home-text09 text-video-tags text-white text">
              <span>Работа с мышлением</span>
            </span>
          </div>
          <div className="home-tag2 video-tags">
            <span className="home-text11 text-video-tags text-white text">
              <span>12+ техник</span>
            </span>
          </div>
          <div className="home-tag3 video-tags">
            <span className="home-text13 text-video-tags text-white text">
              <span>Эффект уже после 1 встречи</span>
            </span>
          </div>
          <div className="home-tag4 video-tags">
            <span className="home-text15 text-video-tags text-white text">
              <span>Комфортно и безопасно</span>
            </span>
          </div>
        </div>
      </div>
      <div className="home-benefits">
        <span className="home-benefitheader text-header text">
          <span className="home-text17">
            Это
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text18">практический</span>
          <span>
            {' '}
            курс для тех, кто готов пройти трансформацию и стать лучшей версией
            себя
          </span>
        </span>
        <div className="home-benefitslist">
          <div className="home-benefit">
            <img alt="image" src="/benefit_heart.webp" className="home-image" />
            <span className="text">
              Научимся помогать себе  и близким людям
            </span>
          </div>
          <div className="home-benefit1">
            <img
              alt="image"
              src="/benefit_energy.webp"
              className="home-image1"
            />
            <span className="text">
              Восстановим ресурсы организма и вылечимся без таблеток
            </span>
          </div>
          <div className="home-benefit2">
            <img
              alt="image"
              src="/benefit_angry.webp"
              className="home-image2"
            />
            <span className="text">
              <span>Сменим ваши разрушающие</span>
              <br className="home-text24"></br>
              <span>убеждения на позитивные</span>
            </span>
          </div>
          <div className="home-benefit3">
            <img alt="image" src="/benefit_slim.webp" className="home-image3" />
            <span className="text">
              Изучим методы работы с телом и научимся его слышать
            </span>
          </div>
          <div className="home-benefit4">
            <img alt="image" src="/benefit_pain.webp" className="home-image4" />
            <span className="text">
              Излечим свои травмы и избавимся от негативного опыта прошлого
            </span>
          </div>
          <div className="home-benefit5">
            <img
              alt="image"
              src="/benefit_precnancy.webp"
              className="home-image5"
            />
            <span className="text">
              Проработаем ваши родовые контексты и установки, пришедшие
              от родственников
            </span>
          </div>
        </div>
        <div className="home-summary">
          <span className="home-glavnoy text-subheader text">
            <span>
              А самое главное — научимся получать удовольствие от каждого
              дня своей жизни
            </span>
          </span>
          <img
            alt="Ellipse266321"
            src="/text-green-round-desktop.webp"
            className="home-rounddesktop"
          />
          <img
            alt="Ellipse266321"
            src="/round_mobile.webp"
            className="home-roundmobile"
          />
        </div>
      </div>
      <div className="home-form-container">
        <FormBlock></FormBlock>
      </div>
      <div className="home-program-header">
        <span className="text-header text home-text30">Программа курса</span>
        <span className="home-text31 text">
          За 4 дня проработаете волнующие вопросы на трёх уровнях: тело,
          психика, глубинное бессознательное.
        </span>
      </div>
      <div className="home-program-day-1">
        <div className="home-side-image"></div>
        <div className="home-desc drop-shadow">
          <div className="home-day-name card-day">
            <span className="text-white text-day-agenda-day text">
              День 1 — Деньги
            </span>
          </div>
          <div className="home-agenda agenda-text-padding">
            <div className="home-agenda-item-1">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts">
                <span className="home-agenda-header text text-day-agenda-header">
                  Практика «Проявление даров»
                </span>
                <span className="text-day-agenda-subtext text">
                  Найдёте решение для лёгкого поступления денег в вашу жизнь.
                  С помощью практики начнёте привлекать деньги и подарки.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-2">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts01">
                <span className="home-agenda-header01 text text-day-agenda-header">
                  Практика «Освобождение денег»
                </span>
                <span className="text-day-agenda-subtext text">
                  Избавитесь от внутренних блоков, из-за которых не могли
                  получить застрявшие по пути к вам ресурсы: зарплату, которую
                  задерживают, или долг, который вам долго не возвращают.
                  Также поймёте, как быстрее закрыть кредит.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-3">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts02">
                <span className="home-agenda-header02 text text-day-agenda-header">
                  Практика «Точка роста»
                </span>
                <span className="text-day-agenda-subtext text">
                  Научитесь замечать ресурсы и возможности для увеличения дохода
                  в десятки раз.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-4">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts03">
                <span className="home-agenda-header03 text text-day-agenda-header">
                  Практика «Поток благосостояния»
                </span>
                <span className="text-day-agenda-subtext text">
                  Поймёте, что мешает вам создавать больше денег, и получите
                  ключи к активации денежного потока.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-program-day-2">
        <div className="home-side-image1"></div>
        <div className="home-desc1 drop-shadow">
          <div className="home-day-name1 card-day">
            <span className="text-white text-day-agenda-day text">
              День 2 — Отношения
            </span>
          </div>
          <div className="home-agenda1 agenda-text-padding card-agenda">
            <div className="home-agenda-item-11">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts04">
                <span className="home-agenda-header04 text text-day-agenda-header">
                  Практика «Волшебное зеркало»
                </span>
                <span className="text-day-agenda-subtext text">
                  Научитесь считывать истинное отношение, намерения и желания
                  другого человека по отношению к вам, даже если он говорит
                  противоположное. Практика пригодится вам для гармонизации
                  отношений с семьёй, бизнес-партнёрами и клиентами.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-21">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts05">
                <span className="home-agenda-header05 text text-day-agenda-header">
                  Практика «Нейтрализация негатива»
                </span>
                <span className="text-day-agenda-subtext text">
                  Восстановите нарушенные связи, например, с друзьями
                  или родственниками, и преобразите болезненные отношения
                  в гармоничные.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-31">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts06">
                <span className="home-agenda-header06 text text-day-agenda-header">
                  Практика «Путешествие к истокам»
                </span>
                <span className="text-day-agenda-subtext text">
                  Поймёте истинные причины и мотивы, которые мешают вам создать
                  отношения мечты.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-41">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts07">
                <span className="home-agenda-header07 text text-day-agenda-header">
                  Практика «Активация притяжения»
                </span>
                <span className="text-day-agenda-subtext text">
                  Увидите, как меняется отношение окружающих. После практики
                  мужчины начнут делать комплименты, дарить подарки проявлять
                  внимание и заботу.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-program-day-3">
        <div className="home-side-image2"></div>
        <div className="home-desc2 drop-shadow">
          <div className="home-day-name2 card-day">
            <span className="text-white text-day-agenda-day text">
              День 3 — Здоровье
            </span>
          </div>
          <div className="home-agenda2 agenda-text-padding card-agenda">
            <div className="home-agenda-item-12">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts08">
                <span className="home-agenda-header08 text text-day-agenda-header">
                  Практика «Сканирование тела»
                </span>
                <span className="text-day-agenda-subtext text">
                  Научитесь видеть внутренние причины дисбалансов и нарушений
                  в своём теле, сможете помогать в этом другим — своим близким
                  или клиентам, если вы специалист любой помогающей профессии.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-22">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts09">
                <span className="home-agenda-header09 text text-day-agenda-header">
                  Практика «Исцеляющий круг»
                </span>
                <span className="text-day-agenda-subtext text">
                  Активируете процесс быстрого исцеления или существенного
                  ослабления симптомов заболеваний, как недавно появившихся,
                  так и хронических.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-32">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts10">
                <span className="home-agenda-header10 text text-day-agenda-header">
                  Практика «Лёгкость»
                </span>
                <span className="text-day-agenda-subtext text">
                  Устраните психосоматические причины, по которым тело перестало
                  быть вашим союзником, появился лишний вес и тяжесть, куда‑то
                  пропала энергия.
                </span>
              </div>
            </div>
            <div className="home-agenda-item-42">
              <img
                alt="image"
                src="/mark-icon-green.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts11">
                <span className="home-agenda-header11 text text-day-agenda-header">
                  Практика «Помощь»
                </span>
                <span className="text-day-agenda-subtext text">
                  Научитесь помогать близким: сможете вернуть баланс и гармонию,
                  а в экстренных ситуациях — успокоить, облегчить боль, убрать
                  воспаление.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-program-day-4">
        <div className="home-side-image3"></div>
        <div className="home-desc3">
          <div className="home-day-name3 card-day">
            <span className="text-white text-day-agenda-day text">
              День 4 — Секретный
            </span>
          </div>
          <div className="home-agenda3 agenda-text-padding card-agenda">
            <div className="home-agenda-item-13">
              <img
                alt="image"
                src="/mark-icon-red.webp"
                className="mark-icon-green"
              />
              <div className="home-agenda-texts12">
                <span className="home-agenda-header12 text-white text text-day-agenda-header">
                  бонусный день для всех участников
                </span>
                <span className="text-day-agenda-subtext text-white text">
                  В этот день мы тоже будем делать совместные практики
                  для получения мгновенных результатов в ещё одной сфере жизни.
                  Какой именно? Узнаете на эфире.
                </span>

                  {FormBlockPopup('bonusButton')}

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-feedback-header">
        <div className="home-headers">
          <span className="home-text32 text">
            Посмотрите несколько отзывов предыдущих групп
          </span>
          <span className="home-text33 text-header text">
            <span>
              Результат уже
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text35">после первого эфира</span>
          </span>
        </div>
        <div className="home-container4">
          <div className="home-left-arrow">
            <img
              alt="image"
              src="/arrow-red-back.webp"
              className="home-image-arrow-back"
            />
          </div>
          <div className="home-rigt-arrow">
            <img
              alt="image"
              src="/arrow-red-forward.webp"
              className="home-image-arrow-forward"
            />
          </div>
        </div>
      </div>
      <div className="home-feedback-desktop overflow-mobile">
        <img
          alt="image"
          src="/feedback-1.webp"
          className="feedback-images home-image06"
        />
        <img alt="image" src="/feedback-2.webp" className="feedback-images" />
        <img
          alt="image"
          src="/feedback-3.webp"
          className="feedback-images home-image08"
        />
      </div>
      <div className="home-feedback-mobile overflow-mobile">
        <img
          alt="image"
          src="/feedback-mobile-1.webp"
          className="feedback-images home-image09"
        />
        <img
          alt="image"
          src="/feedback-mobile-2.webp"
          className="feedback-images"
        />
        <img
          alt="image"
          src="/feedback-mobile-3.webp"
          className="feedback-images home-image11"
        />
      </div>
      <div className="home-about-course-header">
        <span className="text-header text home-text36">
          <span>
            Почему курс вам
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text38">точно</span>
          <span> поможет</span>
        </span>
        <span className="home-text40 text">
          <span>
            Я разработала эффективный метод работы с подсознанием на всех
            уровнях — «Майндпроцессинг».
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>
            Это синтез проверенных психологических подходов и моих авторских
            методик. На курсе я расскажу про метод подробнее, вы опробуете его и
            сами убедитесь в его эффективности.
          </span>
        </span>
      </div>
      <div className="home-about-course overflow-mobile">
        <div className="about-man-card">
          <img
            alt="image"
            src="/ph-1-carl.webp"
            data-tooltip-id="tooltip-1"
            className="home-man-avatar about-card-man-avatar"
          />
          <span className="home-man-name-text text-bold about-man-text text">
            Карл юнг
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-2-mike.webp"
            data-tooltip-id="tooltip-2"
            className="home-man-avatar1 about-card-man-avatar"
          />
          <span className="home-man-name-text1 text-bold about-man-text text">
            Майкл Ньютон
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-3-bert.webp"
            data-tooltip-id="tooltip-3"
            className="home-man-avatar2 about-card-man-avatar"
          />
          <span className="home-man-name-text2 text-bold about-man-text text">
            Берт Хеллингер
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-4-dane.webp"
            data-tooltip-id="tooltip-4"
            className="home-man-avatar3 about-card-man-avatar"
          />
          <span className="home-man-name-text3 text-bold about-man-text text">
            Дейн Хир
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-5-grof.webp"
            data-tooltip-id="tooltip-5"
            className="home-man-avatar4 about-card-man-avatar"
          />
          <span className="home-man-name-text4 text-bold about-man-text text">
            Станислав Гроф
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-6-raig.webp"
            data-tooltip-id="tooltip-6"
            className="home-man-avatar5 about-card-man-avatar"
          />
          <span className="home-man-name-text5 text-bold about-man-text text">
            Вильгельм Райх
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-7-john.webp"
            data-tooltip-id="tooltip-7"
            className="home-man-avatar6 about-card-man-avatar"
          />
          <span className="home-man-name-text6 text-bold about-man-text text">
            Джо Диспенза
          </span>
        </div>
        <div className="about-man-card-margin about-man-card">
          <img
            alt="image"
            src="/ph-8-gary.webp"
            data-tooltip-id="tooltip-8"
            className="home-man-avatar7 about-card-man-avatar"
          />
          <span className="home-man-name-text7 text-bold text-supersmall text">
            Гэри Дуглас
          </span>
        </div>
        <Tooltip id="tooltip-1">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px'}}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Карл Густав Юнг</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Выделил 8 типов людей на основе признаков интроверсии и экстраверсии, рациональности и иррациональности, а также 4 функций психики: мышление, чувство, интуиция и ощущение.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-2">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Майкл Ньютон</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Основоположник метода регрессивной гипнотерапии «Жизнь между жизнями» и автор мировых бестселлеров о путешествиях души и жизни после смерти.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-3">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Берт Хеллингер</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Разработал метод семейных «созвездий» для анализа внутрисемейных конфликтов, где порядок в семье определяет судьбы ее членов.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-4">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Дейн Хир</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Один из создателей набора техник, с помощью которых можно изменить жизнь, и инструментов, созданных для того, чтобы вдохновить людей на создание жизни, о которой они мечтают.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-5">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Станислав Гроф</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Известен во всем мире как исследователь необычных состояний сознания — интуиции, медитации, гипноза, медиумизма.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-6">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Вильгельм Райх</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Основал теорию «мышечного панциря», связав постоянные мышечные напряжения в теле человека с его характером и защитой от болезненного эмоционального опыта.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-7">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Джо Диспенза</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Утверждал, что мозг не отличает внешние события от тех, которые мы представляем, и создал инструменты для того, чтобы люди могли творить свою жизнь.</span>
          </div>
        </Tooltip>
        <Tooltip id="tooltip-8">
          <div style={{ display: 'flex', flexDirection: 'column', width: '320px', padding: '8px' }}>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Bold'}} ><b>Гэри Дуглас</b><br /><br /></span>
            <span style={{fontSize: '16px', fontFamily: 'TildaSans-Regular'}}>Автор методики 32 точек на голове, которая помогает снять напряжение с мозга, что помогает ему восстанавливаться в несколько раз быстрее.</span>
          </div>
        </Tooltip>

      </div>
      <div className="home-writing">
        <span className="text-subheader text home-text44">Об этом писали</span>
        <div className="home-smi-block">
          <img
            alt="image"
            src="/write-argument.webp"
            className="home-smi-card-1 smi-block-card"
          />
          <img
            alt="image"
            src="/write-ledi.webp"
            className="home-smi-card-2 smi-block-card"
          />
          <img
            alt="image"
            src="/write-gazeta.webp"
            className="home-smi-card-3 smi-block-card"
          />
          <img
            alt="image"
            src="/write-people.webp"
            className="home-smi-card-4 smi-block-card"
          />
          <img
            alt="image"
            src="/write-life.webp"
            className="home-smi-card-5 smi-block-card"
          />
          <img
            alt="image"
            src="/write-eva.webp"
            className="home-smi-card-6 smi-block-card"
          />
        </div>
      </div>
      <div className="home-last-call">
        <div className="home-green-block">
          <div className="home-text45">
            <span className="home-header2 text-header text">
              <span>
                Вы ждали знак
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text47">— вот он</span>
            </span>
            <span className="home-text48 text text-last-call">
              <span>
                На курсе вы измените свою жизнь без борьбы и сопротивления,
                а с лёгкостью и благодарностью. Не из нужды, а из любви к себе.
                 Только так вы привлечёте изменения за короткий срок.
              </span>
              <br></br>
              <br></br>
              <span>
                Запишитесь на курс прямо сейчас и получите
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text53">
                полезное упражнение для диагностики отношений с деньгами.
              </span>
            </span>
            <div className="home-container5">
              <div className="home-hero-member-button1">

                {FormBlockPopup('finalButton')}

              </div>
              <HeroMembersWithTextWhite rootClassName="hero-members-with-text-white-root-class-name"></HeroMembersWithTextWhite>
            </div>
          </div>
          <img alt="image" src="/book.webp" className="home-book-image" />
        </div>
      </div>
      <div className="home-footer">
        <div className="home-footer-wrapper">
          <span className="home-contacts text-white text-supersmall text">
            ООО ПАРАГРАФЪ ОГРН 1187746866064 | ИНН 7704462420 | ЛИЦЕНЗИЯ No
            039800 ОТ 19.12.2018 | E-MAIL: ANNA@ANNAMATARI.RU
          </span>
          <div className="home-footer-logo-text">
            <span className="home-footer-university text-white text-supersmall text">
              © 2024 - Институт Новой Психологии
            </span>
            <a
              href="https://npinst.ru/politika.html"
              target="_blank"
              rel="noreferrer noopener"
              className="home-footer-policy text-white text-supersmall text"
            >
              Политика конфиденциальности
            </a>
            <a
              href="https://npinst.ru/oferta.html"
              target="_blank"
              rel="noreferrer noopener"
              className="home-footer-oferta text-white text-supersmall text"
            >
              Договор оферты
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
