import Typewriter from "typewriter-effect";

function HeroTypeText() {
  return (
    <div className="home-hero-main">
    <span className="home-hero-main-text text-header text">
          
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString('Помогу <span style="color:#30B86E">наладить отношения</span> с детьми, мужем и родителями ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу научиться <span style="color:#30B86E">получать деньги в легкости</span> ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу <span style="color:#30B86E">обрести жизнь,</span> о которой всегда мечтали ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу <span style="color:#30B86E">реализовать мечты</span>, амбиции и найти себя ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу <span style="color:#30B86E">исцелить тело,</span> вернуть здоровье и активность ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу <span style="color:#30B86E">удалить глубинные страхи</span> и негативные установки ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу <span style="color:#30B86E">активировать красоту,</span> молодость и женскую энергию ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу <span style="color:#30B86E">построить крепкие отношения</span> и обрести семью ')
                .pauseFor(600)
                .deleteAll(10)
                
                .typeString('Помогу понять, как <span style="color:#30B86E">найти ту самую работу</span> ')
                .pauseFor(600)
                .deleteAll(10)
                
                .start();
            }}
            options={{
              pauseFor: 1000,
              delay: 30,
              loop: true,
              skipAddStyles: true,
            }}
          />
      </span>
    </div>    
  )
};

// import it
// import HeroTypeText from '../components/HeroTypeText'

// use it 
// <HeroTypeText />



export default HeroTypeText